


html body {
  font-family: "Plus Jakarta Sans", sans-serif;
 
}
body {
  padding-top: 70px;
   
}
.stickey{
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white; 
  
}

.HeaderMaindiv{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white; 
}
._line {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
  text-align: center;
}

._line::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 30%;
  height: 7px;
  background-color: #FAAA55;
  border-radius: 5px;
}

._line h1 {
  font-weight: 700;
}


.pricolor{
    color:#016368 ;
}
.pricolor1{
  color:#114338;
}
.countdown-bg{
  background-color: #EDFFFD;
}
.pribgcolor{
    background-color: #016368;
    color: #016368;
}

/* .nav-custom .nav-link.active-link {
  text-decoration: underline;
  color: black;
} */

.active-link {
  text-decoration: underline;
  color: black;
}



.planeimg{
  width: 44px;
  height: 44px;
}

.btn-signup {
    background-color: #016368;
    color:white;
    border-radius: 40px;
    border: 0;
    padding-left:1.5rem;
    padding-right:1.5rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    margin: 0.2rem;
    font-weight: 600;
    
}

.btn-submitt {
  background-color: #016368;
  color:white;
  border-radius: 9px;
  border: 0;
  padding-left:1.5rem;
  padding-right:1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
 margin-top: 0.8rem;
  font-weight: 600;
}
.page6_form_labe label{
  color: var(--Black-950, #000);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 1.25rem;
font-style: normal;
font-weight: 500;
line-height: 148.68%; /* 1.8585rem */
letter-spacing: 0.0125rem;
 
}
.page6_form_labe {
  
  padding-bottom: 1rem;
}

.btn-joinnow {
  background-color: #016368;
  color:white;
  border-radius: 40px;
  border: 0;
  padding-left:1.5rem;
  padding-right:1.5rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  font-weight: 600;
}


.btn-future{
  background: linear-gradient(45deg,  #026D71F5,#00B0B6);

    color:white;
    border-radius: 40px;
    border: 0;
    padding-left:1.5rem;
    padding-right:1.5rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    font-weight: 600;
}

.btn-roadMap {
  background-color: #00868B;
  color:white;
  border-radius: 40px;
  border: 0;
  padding-left:1.5rem;
  padding-right:1.5rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  
  font-weight: 600;
}
.btn-commingsoon {
    background-color: #016368;
    color:white;
    border-radius: 40px;
    border: 0;
    padding-left:3rem;
    padding-right:3rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin: 0.1rem;
    font-weight: 600;
    font-size: 22px;
}

.emailInput{
  
    border-radius: 40px;
    border: 1px solid #00868B;
    font-weight: 500;
    width: 100%;
    font-size: 1.1rem;
    padding: 1.5rem;
    
   
}

.btn-login {
   
    color:#008289;
    background-color: #EEFEFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);

    border-radius: 40px;
    border: 1px solid rgba(1, 99, 104, 0.32);
    padding-left:1.5rem;
    padding-right:1.5rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    margin: 0.2rem;
    font-weight: 600;
}

.btn-home1 {
  background-color: #016368;
  color:white;
  border-radius: 40px;
  border: 0;
  padding: 1rem 1.8rem;
  margin-right: 0.5rem;
   font-weight: 600;
  font-size: 20px;
}

.btn-home1new {
  color: var(--Andromeda-1, #016368);
  border: 0;
  padding: 1.25rem 2rem;
  margin-right: 0.5rem;
  font-weight: 700;
  font-size: 18px;
  border-radius: 624.9375rem;
background: var(--White-50, #FFF);
box-shadow: 2px -2px 12px 0px rgba(0, 0, 0, 0.24) inset, -2px 2px 12px 0px rgba(0, 0, 0, 0.24) inset, 0px 1px 24px 0px rgba(0, 0, 0, 0.12);
}

.btn-home2{
  background-color: #EEFEFF;
    color:#008289;
    border-radius: 40px;
    padding: 1rem 1.8rem;
    margin-left: 0.5rem;
    font-weight: 600;
    font-size: 20px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
    border: 1px solid rgba(1, 99, 104, 0.32);
    
}

.tabnav{
  font-size: 17px;
  font-weight: 600;
}

.tabnav div{
  padding: 5px;
}




.page2 h1{
  font-size: 48px;
  font-weight: 700;
  color: #003438;
}

.page2 p{
  font-size: 24px;
  font-weight: 400;
  color: #003438;
  
}


.btn-footer {
   
    color:black;
    background-color: white;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.15);
    border-radius: 40px;
    border: 2px solid var(--Brand-2, #012269);
    padding-left:1.5rem;
    padding-right:1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin: 0.2rem;
    font-weight: 600;
}

.btn-home {
   
    color:#008289;
    background-color:#E9F7FF;
    box-shadow: 0px 3px 5px #bfbfbf;
    border-radius: 40px;
    border: 0px solid #bfbfbf;
    padding-left:1.5rem;
    padding-right:1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin: 0.2rem;
    font-weight: 600;
}

.nav-custom{
font-family: Plus Jakarta Sans;
font-size: 1.1rem ;
font-weight: 600;
align-items: center;



}


.phracolor{
  text-align: center;
  leading-trim: both;
  
  text-edge: cap;
  font-family: "Plus Jakarta Sans";
  font-size: 3.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.03625rem;
  background: linear-gradient(91deg, #00555A 3.36%, #50B893 95.4%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  
}

.homep1{
  font-size: 2.3rem;
  font-weight: 400;
}

.countdowncolor {
  background: linear-gradient(to right, #00555A,#00555A, #50B893,#00555A,#00555A);
  -webkit-background-clip: text;
  color: transparent;
}

.countdowncolor h1 {
  font-size:120px; 
  font-weight: 800;
}

.countdowncolor p {
  font-size: 32px; 
}
.countdowncolor div {
  text-align: center;
  
}
.countdowncolor .coutDHM{
  font-size: 20px;
}



.custom-hr {
    border-radius: 3px;
    border: 3px solid #00555A; 
    align-items: end;
    width: 30%;
    margin-left:1.2rem;
    
}

.custom-hr1 {
    border-radius: 3px;
    border: 3px solid black; 
    align-items: end;
   width: 20%;
         
    
}



.custom-hrPage5 {
  border-radius: 3px;
  border: 3px solid black; 
  align-items: end;
 width: 20%;
       
  
}

.custom-hr2 {
    border-radius: 3px;
    border: 3px solid black; 
     align-items: end;
         width: 10%;
         
    
}
.hidden {
    display: none;
  }

  .custom-container1{
    border-top: 1px solid var(--Tailwind-slate-300, #CBD5E1);
    background-color: var(--Blue-Stone-50, #EDFFFD);
    margin-top: 5rem;
    margin-bottom: 0;
    padding-left: 2.4rem;

  }
  .homehr{
    color: rgb(146, 145, 145);
    
  }

  .custom-container_new_page2{
    background: linear-gradient(111deg, #024144 33.29%, #04A8B1 100%);
    padding-left: 2.4rem;
  

  }
  
  .custom-scroll {
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
  }
  
  .custom-scroll::-webkit-scrollbar {
    display: none;
  }
  
  .custom-scroll-image {
    width: 400px;
    height: auto;
    
  }
  
  .image-name-overlay {
    position: absolute;
    bottom: 0;
    padding-left: 30px;
    color: white;
    text-align: center;
    padding-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
  
  }

  .cursor-pointer{
    cursor: pointer;
  }
  
  .custom-container2 {
    background: 
      url('assets/page5_bac.png') no-repeat center top, /* image background */
      linear-gradient(165.12deg, #024144 10.17%, #00878E 99.79%); /* gradient background */
    background-size: 100% 35%, cover; /* Ensures the image keeps its shape */
    background-position: center top, center; /* Adjusts the image position */
    position: relative;
    margin-top: 4rem;
  }
  

  .page_7_main_div {
    background: 
      url('assets/page_7_bac.png') no-repeat center top, /* image background */
      linear-gradient(106.63deg, #003E42 0%, #07474A 27.16%, #00ADB0 100%);
    background-size: 100% 100%, cover; /* Ensures the image keeps its shape */
    background-position: center top, center; /* Adjusts the image position */
    position: relative;
    margin-top: 4rem;
    margin-bottom: 3rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
    

  }

  .page_7_content{
    padding-left:5rem;
    padding-top: 10%;
  }
  .page_7_content h1{
    color: var(--White-50, #FFF);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 2.35rem;
font-style: normal;
font-weight: 600;
line-height: 135.7%; /* 4.071rem */
  }
  .page_7_content h1 span{
    color: var(--White-50, #FFF);
    leading-trim: both;
    text-edge: cap;
    font-family: "Plus Jakarta Sans";
    font-size: 2.35rem;
    font-style: normal;
    font-weight: 800;
    line-height: 135.7%;
  }

  .page_7_content p{
    color: var(--White-50, #FFF);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 1.625rem;
font-style: normal;
font-weight: 300;
line-height: 148.68%; /* 2.41606rem */
padding-top: 3rem;
padding-bottom: 3rem;
  }

  .page_7_content .page7new h1{
    font-size: 2.6rem;
   
    
    
  }
  .page_7_content .page7new h1 span{
    font-size: 2.6rem;
  }

 

 

  .btn_page7 {
    
    color:#016368;
    border: 0;
    padding-left:5rem;
    padding-right:5rem;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 10rem;
    border-radius: 62.4375rem;
    background: var(--White-50, #FFF);
    box-shadow: 2px -2px 12px 0px rgba(0, 0, 0, 0.24) inset, -2px 2px 12px 0px rgba(0, 0, 0, 0.24) inset, 0px 1px 24px 0px rgba(0, 0, 0, 0.12);
}



  .commingsoonatag{
    color: var(--Brand-2, #012269);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 1rem;
font-style: normal;
font-weight: 800;
line-height: 148.68%; /* 1.48681rem */
letter-spacing: 0.0125rem;}

  .custom-roadmap-box-container{
    background-color: #EDFFFD;
    border-radius: 2px;
    border: 1px solid #00868B80;

  }


  .custom-roadmap-box-container h1{
    color: #07565A;
    font-size: 16px;
  }

  .custom-roadmap-box-container p{
    color: #003438;

  }

  .custom-roadmap-box-container hr {
    border-radius: 3px;
    border: 3px solid #003438;
    width: 10%;
}

.customhr {
  width: 5px;
  background-color: #79F1D6;
}
.customhr1 {
 
  color: #79F1D6;
  margin-top: -5px;
}

.requestDemoBgImg{
  background-image: url("./assets/Vector2.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}



.homeBgImg{
  background-image: url("./assets/Vector1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.page2newbgimgdiv{
  background-image: url("./assets/planebg.png"),url("./assets/andromedashadow.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 160px calc(100% - 40px), 160px calc(100% - 40px);
  
}
.QuoteIcon{
  height: 65px;
}

.custom-features{
  color: var(--Black-900, #3D3D3D);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 172.7%; /* 1.727rem */
letter-spacing: 0.0125rem;
  
}
.page2phr{
  padding:5rem;
}
.newpage2phr{
  padding:5rem;
  padding-right: 8rem;
}

.page2_phra_show u{
  cursor: pointer;
  font-size: 1.1rem;

}

.page2phr p{
  font-size: 22px;
  text-edge: cap;
  line-height: 2.8125rem;
  font-weight: 400;
  color: var(--Blue-Stone-950, #003438);;
  }
  .page2phr h1{
    color: var(--Blue-Stone-950, #003438);
    leading-trim: both;
    text-edge: cap;
    font-family: "Plus Jakarta Sans";
    font-size: 2.9rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.06rem;
    }

    .page2phr h1 span{
      
      text-decoration-line: underline;
      text-decoration-thickness: 7px;
      text-underline-offset: 12px;
      
     
      
      }
      .andro_meda h1 span{
        text-decoration-line: underline;
        text-decoration-thickness: 7px;
        text-underline-offset: 12px;
      }

      .andro_meda h1 {
        line-height: 2.5rem;
      }
    .newpage2phr h1{
      color: white;
      leading-trim: both;
      text-edge: cap;
      font-family: "Plus Jakarta Sans";
      font-size: 3rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.06rem;
      }

      .image-new-page2{
        object-fit: cover;
      }

    .newpage2phr p{
      color: var(--Tailwind-white, #FFF);
      leading-trim: both;
      text-edge: cap;
      font-family: "Plus Jakarta Sans";
      font-size: 1.5rem;
      font-style: italic;
      font-weight: 300;
      line-height: 3rem; /* 177.778% */

        }

    .newpage2phr p span{
      color: var(--Tailwind-yellow-300, #FDE047);
      text-decoration-line: underline;
      text-underline-offset: 5px;
      font-weight: 400;
    
     }
     .Alfredo_name{
      color: var(--Tailwind-white, #FFF);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 1.5rem;
font-style: normal;
font-weight: 700;
line-height: 10px;
letter-spacing: -0.03rem;
     }

     .Engineer_position{
      border-radius: 0.75rem;
background: rgba(0, 0, 0, 0.50);
box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12);
backdrop-filter: blur(3px);
color: var(--Tailwind-white, #FFF);
text-align: center;
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 1rem;
font-style: normal;
font-weight: 500;
padding: 15px 20px;

     }

     

  .page5h1{
  color: white;
    text-align: start;
    font-size: 3.5rem;
    font-weight: 700;
    padding: 1rem;
  }

  .page6phra{
    color: var(--Black-900, #3D3D3D);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 1.25rem;
font-style: normal;
font-weight: 500;
line-height: 148.68%; /* 1.8585rem */
letter-spacing: 0.0125rem;
  }

  .privacy p{
    font-size: 1.2rem;
  }

  .privacy h1{
    font-size: 2.7rem;
  }

  .termspage li {
    font-size: 1.3rem;
    font-weight: 700;
  }  

.termspage li p{
  font-size: 1.1rem;
  font-weight: 400;
}

.termspage li p{
  font-size: 1.1rem;
  font-weight: 400;
}

.termsphra{
  font-size: 1.2rem;
}

.iconsize{
  font-size: 2rem;
}


.custom-roadmap-box-containerbox5{
  background-color: #EDFFFD;
  border-radius: 2px;
  border: 1px solid #00868B80;
  border: none;

}

.custom-roadmap-box-containerbox5 h1{

  font-size: 18px;
}


.blurred {
  backdrop-filter: blur(8px); 
  -webkit-backdrop-filter: blur(8px); 
}

.blurred-text {
  color: transparent;
  text-shadow: 0 0 8px rgba(0,0,0,0.5); 
}

.blurred-line {
  border-color: transparent; 
}



  .custom-container2 iframe {
    height: 400px;
    width: 100%;
  }
 

  .customrounded{
    border-radius: 1.2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .customlogo{
    width: 240px;
    height: 100%;
    object-fit: contain;
  }

  .page7h h1{
    font-size: 3.3rem;
    font-weight: 700;
  }
  
  .page7p p{
    font-size: 2.1rem;
    font-weight: 400;
  }
  .font_weight_page5{
    color: var(--Black-950, #000);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 2rem;
font-style: normal;
font-weight: 600;
line-height: 148.68%; /* 2.97363rem */
  }
  .footer_p{
    color: var(--Black-950, #000);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 1.5rem;
font-style: normal;
font-weight: 500;
line-height: 2rem; /* 133.333% */
letter-spacing: 0.0125rem;
    
  }
  .useful_link  li{
    font-size: 1.1rem;
  }
  .footer_div_li li{
    font-size: 1.1rem;
  }
  .new_image {
    width: 100px;
    height: auto;
    margin-top: 5%;
  }
  .underline_img{
   margin-bottom: -3%;
   margin-left: 18%;
  }
  .page5_underline{
    width: 30%;
   }
   .page6phra_h1{
    color: var(--Black-950, #000);

leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 3rem;
font-style: normal;
font-weight: 700;
line-height: 148.68%; /* 4.46038rem */
letter-spacing: -0.0625rem;
text-transform: capitalize;
  
   }
   .company_registernumber{
    color: var(--Black-900, #3D3D3D);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 0.75rem;
font-style: normal;
font-weight: 500;
line-height: 1px;
letter-spacing: 0.0125rem;
  
   }
   .footer_link_heading h5{
    color: #000;
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: 148.68%; /* 1.48681rem */
letter-spacing: 0.0125rem;
   }
  
   .footer_link_heading ul li{
    color: #000;
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: 148.68%; /* 1.30094rem */
letter-spacing: 0.0125rem;
   }
  
   .btn-footer {
     
    color: var(--Black-950, #000);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 0.875rem;
font-style: normal;
font-weight: 700;
line-height: 148.68%; /* 1.30094rem */
letter-spacing: 0.0125rem;
display: inline-flex;
padding: 0.75rem 1.5rem 0.75rem 1.75rem;
align-items: center;
gap: 0.5rem;
  }
  
  .footer_last_phra{
    color: var(--Black-500, #6D6D6D);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: 148.68%; /* 1.30094rem */
letter-spacing: 0.0125rem;
  }

   .footer_email {
    text-decoration: none;
    color: var(--Black-900, #3D3D3D);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.0125rem;
  }

  .mobile-scroll li {
    
    margin-right: 25px; 
    margin-left: 25px;
  }
 
   
  
  


  @media (min-width: 1200px){
    .requestDemoHeaderBgImg{
 
      background-color: #E9FEFF;
      width:100%;
      height: 250px;
      background-size: cover;
      -webkit-clip-path: ellipse(50% 100% at 50% 0%);
      clip-path: ellipse(60% 100% at 50% 0%);
    
      
      
      
      
      }

  }
@media (max-width: 576px) {
  .planeimg {
    width: 28px; 
    height: 28px; 
  }
  .homep1{
    font-size: 17px;
  }
  
  .btn-commingsoon {
   
    border-radius: 40px;
    
    padding-left:1rem;
    padding-right:1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin: 0.1rem;
    font-weight: 500;
    font-size: 13px;
}
.unlog{
  width: 55px;
  height: 55px;
}

.page2 h1{
  font-size: 32px;
 
}

.page2 p{
  font-size: 14px;
}

.countdowncolor h1 {
  font-size:75px; 
}

.countdowncolor p {
  font-size: 16px; 
}

.countdowncolor .coutDHM{
  font-size: 15px;
}

.requestDemoBgImg {
  background-image: none ;
}

.homeBgImg{
  background-image: none;
}



.tabnav{
  font-size: 15px;
  
}

.page2phr{
  padding:1.1rem;
}

.page2_phra_show u{
  cursor: pointer;
  font-size: 0.83rem;

}

.page2phr p{
  color: var(--Blue-Stone-950, #003438);
  leading-trim: both;
  text-edge: cap;
  font-family: "Plus Jakarta Sans";
  font-size: 0.885rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */
}

.page2phr h1{
  font-size: 26px;
  line-height: 2.6rem;
}

.custom-features{
  color: var(--Black-900, #3D3D3D);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: 172.7%; /* 1.51113rem */
letter-spacing: 0.0125rem;
  
 
  
}

.page5h1{
  
  color: white;
  text-align: start;
  font-size: 30px;
  font-weight: 700;
  padding: 1rem;
}

.custom-hrPage5 {
  border: 3px solid white; 
  
  
}

.nav-custom{

  font-size: 0.9rem ;
  font-weight: 700;
  align-items: center;
  border-radius: 10px;
  padding-top: 10px;  
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  
  }

  .nav-custom .nav-link{

   width: 100%;
   text-align: center;
   padding: 0.9rem;
   font-size: 1rem;
   
font-style: normal;
font-weight: 600;

   /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05); */
   
    margin: 2px;
    
  }

  .privacy p{
    font-size: 1.05rem;
  }

  .privacy h1{
    font-size: 2rem;
  }

  .termsphra{
    font-size: 1.1rem;
  }

  .custom-container2 {

    background-size: auto 35%, cover;
    
  }
  .customrounded{
    border-radius:1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

/*  Home*/
  .phracolor{ 
  font-size: 28px;
  
}
.btn-home1 {

  border-radius: 25px;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
   font-weight: 500;
   margin-right: 0;
   margin-bottom: 0.4rem;
  font-size: 15px;
}

.btn-home2 {

  border-radius: 25px;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
   font-weight: 500;
   margin-left: 0;
   margin-top: 0.4rem;
  font-size: 15px;
}


/* page-03*/
.image-name-overlay {
  font-size: 18px;
  font-weight: 600;

}



.emailInput{
  
  border-radius: 30px;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.9rem;
  margin-bottom: 0.4rem;
}


.btn-joinnow {

  border-radius: 30px;
  padding: 0.9rem;
  font-weight: 500;
  font-size: 1rem;

}
.page7h h1{
  font-size: 1.8rem;
  font-weight: 700;
}

.page7p p{
  font-size: 1.4rem;
}
.commingsoonatag{
  
  color: var(--Brand-2, #012269);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 0.875rem;
font-style: normal;
font-weight: 700;
line-height: 148.68%; /* 1.30094rem */
letter-spacing: 0.0125rem;
}


.page_7_content{
  padding-left: 5%;
  padding-right: 5%;
  
}
.page_7_content h1{
  color: var(--White-50, #FFF);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 1.75rem;
font-style: normal;
font-weight: 600;
line-height: 135.7%; /* 2.20513rem */
}
.page_7_content h1 span{
  color: var(--White-50, #FFF);
  leading-trim: both;
  text-edge: cap;
  font-family: "Plus Jakarta Sans";
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: 135.7%;
}

.page_7_content p{
  font-size: 1rem;
  color: white;
  padding-top: 1.4rem;
  padding-bottom: 2rem;
}
.btn_page7 {
   padding-left:0.5rem;
  padding-right:0.5rem;
  font-size: 1.2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 62.4375rem;
background: var(--White-50, #FFF);
box-shadow: 2px -2px 12px 0px rgba(0, 0, 0, 0.24) inset, -2px 2px 12px 0px rgba(0, 0, 0, 0.24) inset, 0px 1px 24px 0px rgba(0, 0, 0, 0.12);
margin-bottom: 1rem;
}

.page_7_content .page7new h1{
  font-size: 1.8rem;
 
  
  
}
.page_7_content .page7new h1 span{
  font-size: 1.8rem;
}
.new_image{
  width: 50px;
  margin-top: -5%;
  
}
.underline_img{
  margin-bottom: -3%;
  margin-left: 0%;
  width: 60%;
 }
 .page5_underline{
  width: 40%;
 }
 .nav_buttons_div button{
  width: 90%;
 
  
 }
 .nav_buttons_div {
  width: 100%;
  padding-bottom: 2rem;
  text-align: center;
 }
 .customlogo{
  width: 220px;

}
.custom-container1{
  border-top: 0;
  padding-top: 2rem;
  padding-bottom: 0rem;
  margin-top: 3rem;
  margin-bottom: 0rem;
  padding-left: 0rem;

}
.page6_form_labe label{
  
  color: var(--Black-950, #000);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: 148.68%; /* 1.30094rem */
letter-spacing: 0.0125rem;
 
}
.page6_form_labe {
  
  padding-bottom: 0.8rem;
}
.useful_link  li{
  font-size: 1.05rem;
}
.footer_div_li li{
  font-size: 1.05rem;
}
.footer_p{
  color: var(--Black-950, #000);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 1.5rem;
font-style: normal;
font-weight: 500;
line-height: 2rem; /* 133.333% */
letter-spacing: 0.0125rem;
  
  
}
.page_7_main_div {
 

    margin-top: 3.5rem;
    margin-bottom: 3rem;
    padding-bottom: 4rem;
    padding-top: 5rem;
    

  
  

}
.custom-container2 {

  margin-top: 2.2rem;
}

.font_weight_page5{
  color: #000;
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 1.25rem;
font-style: normal;
font-weight: 600;
line-height: 148.68%; /* 1.8585rem */
letter-spacing: 0.0125rem;
}

.page6phra{
  color: var(--Black-900, #3D3D3D);
  leading-trim: both;
  text-edge: cap;
  font-family: "Plus Jakarta Sans";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 148.68%; /* 1.30094rem */
  letter-spacing: 0.0125rem;
}
.page6phra_h1{
  color: var(--Black-950, #000);
  leading-trim: both;
  text-edge: cap;
  font-family: "Plus Jakarta Sans";
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 148.68%; /* 2.97363rem */
  letter-spacing: -0.0625rem;
  text-transform: capitalize;
}

.company_registernumber{
  color: var(--Black-900, #3D3D3D);
  leading-trim: both;
  text-edge: cap;
  font-family: "Plus Jakarta Sans";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1px;
  letter-spacing: 0.0125rem;

 }
 .footer_link_heading h5{
  color: #000;
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: 148.68%; /* 1.48681rem */
letter-spacing: 0.0125rem;
 }

 .footer_link_heading ul li{
  color: #000;
leading-trim: both;
text-edge: cap;
font-family: Plus Jakarta Sans;
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: 148.68%; /* 1.30094rem */
letter-spacing: 0.0125rem;
 }

 .btn-footer {
   
  color: var(--Black-950, #000);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 0.64725rem;
font-style: normal;
font-weight: 700;
line-height: 148.68%; /* 0.93263rem */
letter-spacing: 0.00894rem;
}

.footer_last_phra{
  color: var(--Black-500, #6D6D6D);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: 148.68%; /* 1.30094rem */
letter-spacing: 0.0125rem;
}
.footer_email{
  color: var(--Black-900, #3D3D3D);
leading-trim: both;
text-edge: cap;
font-family: "Plus Jakarta Sans";
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.0125rem;
}

.Alfredo_name{
font-size: 1.25rem;

 }

 .Engineer_position{
  font-size: 1rem;
  padding: 10px 18px;
 }
 .page2newbgimgdiv{
  background-image: url("./assets/planebg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center
  
}
.QuoteIcon{
  height: 32px;
  
}

.newpage2phr{
  padding:1rem;
  
}
.newpage2phr h1{
  
  font-size: 1.5rem;
  
  }

.newpage2phr p{
  font-size: 1rem;
  line-height: 2rem;

    }

.newpage2phr p span{
  font-size: 1rem;

 }

 .btn-home1new {
  padding: 1rem 2rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  

  
}

.custom-container_new_page2{
  padding-left: 0rem;

}
body {
  padding-top: 40px;
   
}

.mobile-scroll {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.mobile-scroll li {
  display: inline-block;
  margin-right: 5px; 
  margin-left: 5px;
}

.mobile-scroll::-webkit-scrollbar {
  display: none;
}

}
  
  


